import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { UserPreferences } from '../../models/UserPreferences';
import CurrentUserService from '../../services/CurrentUserService';
import Checkbox from '../../components/shared/form-control/Checkbox';
import { Heading, HeadingSize } from '../../components/shared/text/Heading';

const EmailPreferences: FC = () => {
  const params = useParams<{ clientId: string }>();
  const { t } = useTranslation('email-preferences');
  const [preferences, setPreferences] = useState<UserPreferences>({} as UserPreferences);

  useEffect(() => {
    CurrentUserService.getPreferences().then((res) => {
      setPreferences(res.data);
    });
  }, [params.clientId]);

  const updatePreferences = (key: string, value: boolean) => {
    setPreferences((prev) => {
      return { ...prev, [key]: value };
    });
    CurrentUserService.savePreferences({ ...preferences, [key]: value });
  };

  return (
    <div className="mb-6">
      <div className="my-6 flex h-full flex-col">
        <Heading size={HeadingSize.H3}>{t('heading')}</Heading>

        <div className="bg-background-1 h-full px-4 py-2">
          <div>{t('description')}</div>
          {Object.entries(preferences).map(([key, value]) => (
            <div key={key} className="border-gray-5 mt-2 border-2 bg-white" data-cy={`email-preference-${key}`}>
              <div className={`flex w-full items-center`}>
                <div className="flex w-full items-center justify-between p-4">
                  <div className="flex flex-col">
                    <div className="font-medium">{t(`preferences.${key}.title`) as string}</div>
                    <div className="text-dpm-12">{t(`preferences.${key}.description`) as string}</div>
                  </div>
                  <div>
                    <Checkbox value={value} onChange={(selectedValue) => updatePreferences(key, selectedValue)} slider />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EmailPreferences;
